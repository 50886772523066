.page-body {
  height: calc(100vh - 136px);
  background: #f2f2f2;
}
.page-body .password-reset {
  width: 680px;
  height: 700px;
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
  gap: 24px;
  align-items: center;
  position: relative;
}
.page-body .password-reset .cancel-button {
  font-size: 24px;
  position: absolute;
  top: 32px;
  left: 32px;
  cursor: pointer;
}
.page-body .password-reset__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}
.page-body .password-reset .password-from {
  width: 460px;
  margin: 0 auto;
}
.page-body .password-reset .input-text,
.page-body .password-reset .arco-input-group-wrapper .arco-input-inner-wrapper {
  height: 56px;
  padding: 16px 20px;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid var(--text-3, rgb(134, 144, 156));
  background-color: #fff;
}
.page-body .password-reset .input-text {
  width: 350px;
}
.page-body .password-reset .arco-input {
  font-size: 12px;
}
.page-body .password-reset .submit-button {
  height: 68px;
  padding: 16px 24px;
  gap: 8px;
  border-radius: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: rgb(255, 255, 255);
}
.page-body .password-reset .logo-content {
  margin-top: 80px;
}
.page-body .password-reset .verify-button {
  width: 98px;
  height: 56px;
  padding: 16px 10px;
  border-radius: 16px;
  border: 1px solid var(--text-3, rgb(134, 144, 156));
  background-color: #fff;
  color: rgb(22, 93, 255);
  text-align: center;
}
.page-body .password-reset .verify-button-dis {
  color: rgb(153, 153, 153);
}
.page-body .arco-input {
  caret-color: var(--Color, rgb(22, 93, 255));
}
.page-body .line {
  width: 300px;
  height: 1px;
  background-color: var(--black-10, rgba(28, 28, 28, 0.1));
  overflow: hidden;
  margin: 0 auto;
}